.venue-item-quantity__quantity, .venue-item-quantity__quantity-price-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.venue-item-quantity__quantity {
  color: #313332;
  line-height: normal;
  height: 52px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #fdfefe;
  border: 1px solid #f0f5f6;
  width: 50%;
}
.venue-item-quantity__button {
  max-height: 18px;
}

.venue__button--no-border {
  border: none;
  padding: 0;
  margin: 0;
}
.venue__button--icon {
  border: 1px solid #f0f5f6;
  padding: 3.2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.grid-container {
  background-color: transparent;
}
